<template>
  <div class="change-log">
    <h1 class="change-log__title">{{ $t('change-log') }}</h1>
    <admin-table></admin-table>
  </div>
</template>

<script>
import AdminTable from "../../components/common/admin/Table.vue";

export default {
  name: "ChangLog",
  components: {AdminTable}
}
</script>

<style lang="scss" scoped>
.change-log {
  display: flex;
  justify-content: space-between;
  padding: 150px 50px 56px 50px;
  min-height: 100vh;
  height: 100%;
  background: #F5F5F5;
  gap: 10px;

  @media(max-width: 992px) {
    padding: 104px 20px 56px 20px;
    flex-direction: column;
    gap: 30px;
    justify-content: normal;
  }

  &__title {
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #343432;
  }
}
</style>