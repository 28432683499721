<template>
  <div class="table">
    <h2 class="table__title">{{ $t('incident-types') }}</h2>
    <div class="table__body body">
      <div class="body__header">
        <div class="body__header-date text" style="max-width: 120px">{{ $t('date') }}</div>
        <div class="body__header-time text" style="max-width: 78px">{{ $t('time') }}</div>
        <div class="body__header-user text" style="max-width: 179px">{{ $t('user') }}</div>
        <div class="body__header-event text" style="max-width: 250px">{{ $t('event') }}</div>
        <div class="body__header-value text" style="max-width: 187px">{{ $t('old-meaning') }}</div>
        <div class="body__header-value text" style="max-width: 187px">{{ $t('new-meaning') }}</div>
      </div>
      <div class="body__row" v-for="(item, key) in 5" :key="key">
        <div class="body__row-date text" style="max-width: 120px">09.05.2023</div>
        <div class="body__row-time text" style="max-width: 78px">14:30</div>
        <div class="body__row-user text" style="max-width: 179px">ivanov123@mail.ru</div>
        <div class="body__row-event text" style="max-width: 250px">{{ $t('change-password') }}</div>
        <div class="body__row-value text" style="max-width: 187px">1234567890</div>
        <div class="body__row-value text" style="max-width: 187px">0987654321</div>
      </div>
    </div>
<!--    <div class="table__action">-->
<!--      <svg width="14" height="14" viewBox="0 0 14 14">-->
<!--        <rect y="7.875" width="1.75" height="14" rx="0.806452" transform="rotate(-90 0 7.875)" fill="#CE2121"/>-->
<!--        <rect x="6.125" width="1.75" height="14" rx="0.806452" fill="#CE2121"/>-->
<!--      </svg>-->
<!--      <p>Новый тип</p>-->
<!--    </div>-->
  </div>
</template>

<script>

export default {
  name: "TableCommon",
  data() {
    return {
      tableOffsetWidth: 0
    }
  },

  mounted() {
    addEventListener('resize', () => this.tableInfo());

    this.tableInfo();
  },

  methods: {
    tableInfo() {
      const body = document.querySelector('body');
      if (body) {
        this.tableOffsetWidth = body.offsetWidth - 40 + 'px';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  background: #FFFFFF;
  border-radius: 20px;
  height: max-content;
  width: v-bind(tableOffsetWidth);
  overflow-y: auto;

  &__title {
    margin: 20px 0 20px 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1B1A1F;
  }
  .body {
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      background: #E21F1F;

      @media (max-width: 590px) {
        width: 548px;
      }

      .text {
        font-weight: 400;
        font-size: 12px;
        line-height: 103%;
        color: #FFFFFF;
        width: 100%;
        padding: 10px 0 10px 20px;

        @media (max-width: 768px) {
          padding: 10px 0 10px 10px;
        }
      }
    }

    &__row {
      display: flex;

      .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #1B1A1F;
        width: 100%;
        padding: 10px 0 10px 20px;
        border-width: 0 1px 1px 1px;
        border-style: solid;
        border-color: #F5F5F5;

        @media (max-width: 768px) {
          padding: 10px 0 10px 10px;
        }
      }
    }
  }
  &__action {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px;
    cursor: pointer;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #CE2121;
    }
  }
}
</style>